<template lang="pug">
  Auth(@loggedIn="onLoggedIn" @loginFailed="loginFailed")
    NowLoading(:show="showNowLoading")
    div.wrap-module-invitation.f.fh
      div.invitation-content
        div(v-if="inviter").wrap-inviter.mb40
          div.f.fc.mb20
            v-avatar(size="120px")
              v-img(:src="inviter.iconURL")
          span.text-center.block.fz14.mb4 「{{meeting.title}}」
          span.text-center.block.fz14.mb4 {{inviter.name}}がミーティングに招待しました。
          span.text-center.block.fz14 参加可能な日時を送信して日程調整をしましょう。
        div.mb30
          SignIn(@signInSuccess="signInSuccess"
            :googleSignInLabel="'Googleカレンダーの予定から自動調整する'")
          //- div(@click="showDialog = true").google-auth-btn.f.fm.px16.py10.my12
            div.icon-img
              v-img(src="/img/google-icon.svg").mr12
            span.block.fz13.bold.text-center Googleカレンダーの予定から自動調整する
          div.info-sign-in.mt20
            span.text-center.block.fz10.mb4 * Googleカレンダーをお持ちの方はMeetAwesomeと
            span.text-center.block.fz10.mb4 連携させることで自動でミーティング日時の調整を行います。
            span.text-center.block.fz10 （すでにアカウントをお持ちの方もこちら）
        div.wrap-anonymous-sign-in
          div.f.fc.mb24
            span(@click="onManual").btn.fz14.cursor-pointer 手動で参加可能な日時を選択
          div.info-sign-in
            span.fz10.block.text-center.mb4 * Googleカレンダーを連携しない場合は可能な日程を
            span.fz10.block.text-center 手動で選択することでAIが自動日程調整を開始します
        //- div(v-show="showDialog" @click="showDialog = false").wrap-overlay.f.fh
          div(@click.stop).overlay-content
            div.window-content.py8.px4
              div.mb12
                v-img(src="/img/dialog.png")
              div
                span.block.fz12 現在、MeetAwesomeはβ版のためGoogleによるアプリレヴューが終わっていません。そのためサインアップ時にこのような画面が表示されますが、上記のような継続ボタンを押していただくことで問題なくご利用いただけます。
                SignIn(@signInSuccess="signInSuccess"
                  googleSignInLabel="Googleカレンダーの予定から自動調整する"
                  authContainerId="firebaseui-auth-container2")
</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.wrap-module-invitation {
  width: 100%;
  min-height: 100vh;
  // padding-top: $header_height;
  .invitation-content {
    width: 100%;
    max-width: $base_max_width_px;
    margin: 0 auto;
    .wrap-inviter {
      width: $base_width_percent;
      margin: 0 auto;
    }
    // .google-auth-btn {
    //   width: 320px;
    //   background: #fff;
    //   border-radius: 3px;
    //   box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    //   .icon-img {
    //     width: 32px;
    //   }
    //   span {
    //     width: 100%;
    //     color: #757575;
    //   }
    // }
    .info-sign-in {
      color: $secondary_text_color;
    }
    .wrap-anonymous-sign-in {
      .btn {
        border-bottom: solid 1px $secondary_text_color;
      }
    }
  }

  // .wrap-overlay {
  //   position: fixed;
  //   width: 100%;
  //   height: 100%;
  //   left: 0;
  //   top: 0;
  //   background: rgba(0, 0, 0, 0.4);
  //   z-index: 100;
  //   .overlay-content {
  //     width: 98%;
  //     max-width: $base_max_width_px;
  //     background: #fff;
  //     border-radius: 3px;
  //     overflow: hidden;
  //     .window-content {
  //       width: 96%;
  //       margin: 0 auto;
  //     }
  //   }
  // }
}
</style>

<style lang="scss" scoped>
.wrap-module-invitation {
  .firebaseui-card-content {
    padding: 0 !important;
  }
}
</style>

<script>
import database from '@/database'
import { firebase } from '@/components/utils/firebase'
import { createNamespacedHelpers } from 'vuex'
const {
  mapState: mapStateAuth,
  mapMutations: mapMutationsAuth,
  mapActions: mapActionsAuth
} = createNamespacedHelpers('auth')
import Auth from '@/components/shared/Auth'
import SignIn from '@/components/sign-in/firebase-sign-in-ui'
import NowLoading from '@/components/shared/NowLoading'

export default {
  components: {
    Auth,
    SignIn,
    NowLoading
  },
  computed: {
    ...mapStateAuth(['uid', 'isAnonymous'])
  },
  data() {
    return {
      meeting: null,
      inviter: null,
      showNowLoading: true
      // showDialog: false
    }
  },
  async created() {
    if (this.isAnonymous) this.signOut()

    let attendeeEvents = await database
      .meetingCollection()
      .getAttendeeEventsByEmail(this.$route.params.meetingId, this.$route.params.inviteeEmail)
    if (!attendeeEvents.empty)
      this.$router.push(
        `/send-events-complete/${this.$route.params.meetingId}/${this.$route.params.inviteeEmail}`
      )

    this.meeting = await database.meetingCollection().findById(this.$route.params.meetingId)

    if (this.meeting.status === 'adjusted' || this.meeting.status === 'failed')
      this.$router.push(`/meeting/${this.$route.params.meetingId}`)

    this.inviter = await database.userCollection().findById(this.meeting.createdBy)
    console.log('meeting', this.meeting)
  },
  methods: {
    ...mapMutationsAuth(['updateAccessToken']),
    ...mapActionsAuth(['signInAnonymously', 'signOut']),
    async onLoggedIn() {
      const meeting = await database.meetingCollection().findById(this.$route.params.meetingId)
      if (meeting.createdBy === this.uid) {
        const me = meeting.attendee.filter((e) => e.email === this.$route.params.inviteeEmail)[0]
        if (me.responsed) {
          this.$router.push(`/meeting/${this.$route.params.meetingId}`)
        } else {
          this.$router.push(`/create-meeting/send-attendee-events/${this.$route.params.meetingId}`)
        }
      }
      this.showNowLoading = false
    },
    loginFailed() {
      console.log('loginFailed')
      this.showNowLoading = false
    },
    async signInSuccess({ firebaseUser, token }) {
      console.log('signInSuccess', { token })

      this.showNowLoading = false

      this.updateAccessToken(token.accessToken)

      // await this.sleepByPromise(0.8)
      // let firebaseUser = await firebase.auth().currentUser
      // console.log('firebaseUser:', firebaseUser)

      if (firebaseUser.uid) {
        this.showNowLoading = true

        let user = await firebase.auth().currentUser
        let userDoc = await database.userCollection().findById(firebaseUser.uid)

        if (!userDoc) {
          let userObj = {
            uid: user.uid,
            name: user.displayName,
            profile: 'No Profile',
            iconURL: user.photoURL,
            email: user.email,
            emailAsInvitee: this.$route.params.inviteeEmail,
            isAnonymous: false,
            lastSignInTime: user.metadata.lastSignInTime,
            signUpAt: new Date(),
            createdAt: user.metadata.creationTime,
            timeZone: Intl ? Intl.DateTimeFormat().resolvedOptions().timeZone : 'Asia/Tokyo'
          }

          await database.userCollection().signUp(userObj, {
            idToken: token.idToken,
            accessToken: token.accessToken
          })

          // mixpannel
          // identifyTrackedUser(firebaseUser.uid)
          // signUpForTracking(userObj)
          // console.log('sign up user:', userObj)
        } else {
          await database.userCollection().setGoogleToken(firebaseUser.uid, {
            idToken: token.idToken,
            accessToken: token.accessToken,
            createdBy: firebaseUser.uid,
            createdAt: new Date()
          })
          // mixpannel
          // identifyTrackedUser(firebaseUser.uid)
        }

        this.showNowLoading = false

        this.$root.$emit('sendTrackingEventByClick', { 
          message: 'click_calendar_connection_btn',
          option: { meetingId: this.$route.params.meetingId }
        })

        this.$router.push(
          `/invitation/send-attendee-events/${this.$route.params.meetingId}/google/${this.$route.params.inviteeEmail}`
        )
      } // if (firebaseUser.uid)
    },
    async onManual() {
      // sign in anonymously
      if (!this.uid) {
        await this.signInAnonymously()
        const firebaseUser = await firebase.auth().currentUser

        let user = {
          uid: firebaseUser.uid,
          type: 'anonymous'
        }
        console.log('anonymousSignIn', user)
        // mixpannel
        // signUpForTracking(user)
        // identifyTrackedUser(this.uid)
        // sendTrackingEvent(`${this.$route.path}:onManual`, user, this.uid)
      } else {
        // identifyTrackedUser(this.uid)
        // sendTrackingEvent(`${this.$route.path}:onManual`, {}, this.uid)
      }

      this.$root.$emit('sendTrackingEventByClick', { 
        message: 'click_manual_input_btn',
        option: { meetingId: this.$route.params.meetingId }
      })

      this.$router.push(
        `/invitation/send-attendee-events/${this.$route.params.meetingId}/manual/${this.$route.params.inviteeEmail}`
      )
      // this.$router.push(
      //   `/send-events/${this.$route.params.meetingId}/${this.$route.params.inviteeEmail}`
      // )
    },
    sleepByPromise(sec) {
      return new Promise((resolve) => setTimeout(resolve, sec * 1000))
    }
  }
}
</script>
