<template lang="pug">
  Auth(@loggedIn="onLoggedIn")
    //- Header(:content="headerContent")
    div.wrap-invitation.f.fh
      ModuleInvitation

</template>

<style lang="scss" scoped>
.wrap-invitation {
  width: 100%;
  min-height: 100vh;
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
import Auth from '@/components/shared/Auth'
import Header from '@/components/shared/Header'
import ModuleInvitation from '@/components/module/ModuleInvitation'

export default {
  components: {
    Auth,
    Header,
    ModuleInvitation
  },
  data() {
    return {
      // headerContent: {
      //   title: 'Tmp',
      //   left: {
      //     label: 'Home',
      //     icon: 'mdi-home',
      //     to: '/tmp'
      //   },
      //   right: {
      //     label: 'Acount',
      //     icon: 'mdi-account',
      //     to: '/tmp'
      //   }
      // }
    }
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  methods: {
    onLoggedIn() {}
  }
}
</script>
